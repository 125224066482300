:root {
    font-size: 62.5%;
    width: 100%;
}

article, aside, details, figure, figcaption, footer, header, hgroup, menu, nav, section {
    display: block;
}

div, span, h1, h2, h3, h4, h5, h6, p, pre, a, address, code, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, section, summary, time {
    border: 0;
    font-family: Verdana, sans-serif;
    font-size: 1.4rem;
    margin: 0;
    padding: 0;
    vertical-align: baseline;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: bolder;
    line-height: 1;
    text-align: left;
}

a {
    &.plain {
        text-decoration: none;
        cursor: pointer;
        color: inherit;
        font-style: inherit;
        text-shadow: inherit;
        font-weight: inherit;
        font-family: inherit;
        line-height: inherit;
        text-align: inherit;
    }
}

body {
    background: #E5EEF6;
    border: 0;
    font-family: 'Myriad', sans-serif;
    margin: 0 0 9.2rem;
    padding: 0;
    vertical-align: baseline;
}

footer {
    background: #191919;
    bottom: 0;
    height: 6.2rem;
    left: 0;
    min-width: 75rem;
    padding: 1.5rem 2rem;
    position: absolute;
    right: 0;
    text-align: center;

    ul {
        margin-bottom: 1rem;

        li a {
            text-transform: uppercase;
            padding: .5rem;
            margin: .5rem;
        }

        &:nth-child(2) li a {
            text-transform: none;
            font-size: 1rem;
        }
    }

    li {
        display: inline-block;
        color: #b2b2b2;
        margin: 0;
        vertical-align: bottom;

        &:after {
            color: #b2b2b2;
            content: " ";
        }

        &:last-child:after {
            content: "";
        }
    }

    a#copynfmlink {
        color: #b2b2b2;
    }
}

h1 {
    font-size: 4rem;
}

h2 {
    font-size: 3rem;
}

h3 {
    font-size: 2.5rem;
}

h4 {
    font-size: 2rem;
}

h5 {
    font-size: 1.6rem;
}

header {
    &.main {
        background: white;
        box-shadow: 0 .3rem 1rem #4d4d4d;
        height: 6rem;
        min-width: 75rem;
        padding: 2rem 0;
        position: relative;
        text-align: center;
        width: 100%;
        z-index: 10;

        a img {
            display: inline-block;
            vertical-align: top;
        }

        h1 {
            display: inline-block;
            position: relative;
            text-align: center;
            vertical-align: middle;
        }
    }
}

html {
    border: 0;
    margin: 0;
    min-height: 100%;
    padding: 0;
    position: relative;
    vertical-align: baseline;
}

li {
    list-style: disc inside;
}

main {
    background: white;
    border-radius: .6rem;
    box-shadow: .3rem .3rem 1rem #4d4d4d;
    display: block;
    padding: 1.5rem 3rem;
    margin: 2rem auto 14rem;
    width: fit-content;

    h4 {
        background: #6CA0E8;
        background-image: linear-gradient(#6CA0E8, #5680BA);
        border-top-left-radius: .5rem;
        border-top-right-radius: .5rem;
        color: #fdfdfd;
        margin: -1.5rem -1.5rem .5rem -1.5rem;
        padding: .5rem 1.5rem;
        text-shadow: .2rem .2rem .4rem #282828;
    }

    h5 {
        line-height: 1.4;
    }
}

ol {
    list-style: none;
    display: block;
}

p {
    margin: 1rem 0;
}

svg:not(:root) {
    overflow: visible;
}

select {
    background: linear-gradient(#fbfbfb, #e9e9e9);
    border: .1rem solid #707070;
    border-radius: .4rem;
    color: #000;
    cursor: pointer;
    display: inline-block;
    margin: 0;
    outline: none;
    padding: .5rem;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
    margin: 1rem auto;

    th {
        font-size: 1.6rem;
        font-weight: bold;
        line-height: 1.1;
        vertical-align: bottom;
    }

    td {
        vertical-align: middle;
    }

    &.compact {
        td {
            height: 1.4rem;
            padding: .1rem .6rem;
        }

        th {
            height: 1.4rem;
            padding: .4rem .6rem;
        }
    }

    &.zebra {
        border: .1rem solid #dadada;

        th {
            border: .1rem solid #dadada;

            &:first-child {
                padding-left: .6rem !important;
            }
        }

        td {
            border: .1rem solid #dadada;

            &:first-child {
                padding-left: .6rem !important;
            }
        }

        tr:nth-child(even) {
            background: #F1F1F1;
        }
    }

    &.zebra-flat-green {
        &.bold-title {
            tbody tr th {
                font-weight: bolder;
            }

        }

        &.no-border {
            thead, tbody tr {
                border: none !important;
            }
        }

        &.reverse {
            margin: 0;

            tr {
                th {
                    font-weight: bolder;
                }

                &:nth-child(odd) {
                    background: white;
                }

                &:nth-child(even) {
                    background: #D6EAC9;
                }
            }
        }

        thead {
            background: white;
            border: thin solid #55AA77;

            th {
                padding: .5rem;
            }
        }

        tbody {
            tr {
                border: thin solid #559966;

                &:nth-child(odd) {
                    background: #D6EAC9;
                }

                td, th {
                    font-weight: normal;
                    padding: .5rem;
                    text-align: left;

                    &.nopad {
                        padding: 0;
                    }
                }
            }
        }
    }

    &.zebra-grey {
        th {
            background: #FFF;

            &.horizontal {
                text-align: center;
            }

            &.vertical {
                text-align: left;
            }
        }

        td, th {
            border: .1rem solid #dadada;
            padding: .5rem;
        }

        tbody tr:nth-child(odd) {
            background-color: #f2f2f2;
        }
    }
}

textarea {
    border: thin solid #707070;
    border-radius: .4rem;
    padding: .3rem;
}

ul {
    display: block;
    list-style: none;
}

.clear {
    clear: both;
}

.error {
    color: #ff3300;
}

.left {
    float: left;
}

.logout-inline {
    color: inherit;
    display: inline;
    line-height: inherit;
    margin: 0;

    button {
        background-image: none;
        background-color: transparent;
        border: none;
        box-shadow: none;
        color: inherit;
        cursor: pointer;
        display: inline;
        font-size: 1rem;
        line-height: inherit;
        margin: 0 1rem 0 .5rem;
        padding: 0;
        vertical-align: bottom;
    }
}

.page-content {
    td {
        padding: .4rem .6rem;
    }

    th {
        padding: .4rem .6rem;
    }
}

.right {
    float: right;
}

.spinner {
    display: block;
    height: 5rem;
    width: 5rem;
}

#page-wrapper {
    margin-bottom: 10.8rem;
}

#top-menu {
    background: #54b247;
    background-image: linear-gradient(#54b247, #45923a);
    border-bottom: .1rem solid #45923a;
    display: block;

    li {
        background: #54b247;
        background-image: linear-gradient(#54b247, #45923a);
        border-bottom: .1rem solid #45923a;
        display: block;
        list-style: none;
    }

    .level-1 {
        margin: 0 auto;
        position: relative;
        text-align: center;
        width: 90%;

        .active {
            border-bottom: .2rem solid #FFFF55;
        }

        > li {
            display: inline-block;
            margin: 0;
            text-align: center;

            > a, > span {
                display: block;
                padding: 1.2rem 2rem;
            }

            &:hover, > a:hover {
                .level-2 {
                    display: block;
                    margin-top: .2rem;
                    position: absolute;
                    z-index: 100;

                    > li {
                        background: #77BB55;
                        display: block;
                        margin: 0 2rem;
                        padding: .2rem;
                        text-align: left;

                        > a {
                            padding: .5rem;
                        }


                        .level-3 {
                            display: block;
                            margin-top: .2rem;
                            position: relative;
                            z-index: 1000;

                            > li {
                                background: #99DD66;
                                display: block;
                                margin: 0 2rem;
                                padding: .2rem;
                                text-align: left;
                            }

                            > a {
                                padding: .5rem;
                            }
                        }

                    }

                    .level-3 {
                        display: none;
                    }
                }
            }
        }
    }

    .level-2 {
        display: none;
    }
}

@media print {
    body {
        background: #FFF;
    }

    #page-wrapper {
        margin-bottom: 0;

        nav {
            display: none;

            li a.active {
                display: block;
                font-size: 1rem;
            }
        }

        main {
            border-radius: 0;
            box-shadow: none;
            margin: 0;
        }

        footer {
            display: none;
        }
    }
}
